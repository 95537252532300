import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);





  return (

  

    <>

    {
      about.map(a=> <div className="bg_img" data-background="assets/img/bg/mr_bg_2.jpg" id="about-sec">
        {/* feature start */}
        
        {/* feature end */}
        {/* about start */}
        <section className="about pb-115">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="sec-title sec-title--marketing">
                  <span className="subtitle">Who we are?</span>
                  <h2 className="title mb-45">{a.aboutHeading} 
                    <span className="xb-title--typewriter">
                      <span className=""> {a.aboutText}</span>
                     
                    </span>
                  </h2>
                  <p>{a.aboutDetails} <br />  <br />  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="rm-about-img text-center text-lg-end"  >
                  <img className="slide-up-down" src={a.aboutImgOne} alt />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* about end */}
      </div> )
    }

    
   



    
    </>
  );
};

export default AboutSection;
