import React, { useEffect, useState } from 'react';

const WorkSection = () => {

  const [stepwork, setWork] = useState([]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/worksec`)
      .then((res) => res.json())
      .then((info) => setWork(info));
  }, []);


  return (


    // <>
    //   <section className="space overflow-hidden position-relative space" style={{
    //     backgroundImage:
    //       "url(https://i.postimg.cc/26j1rhdM/process-bg-1.jpg)",
    //     backgroundSize: "cover",
    //     backgroundRepeat: "no-repeat",

    //   }}>
    //     <div className="container">
    //       <div className="title-area text-center">
    //         <span className="sub-title">Work Process</span>
    //         <h2 className="sec-title">How it Work Processing</h2>
    //       </div>
    //       <div className="step-wrap">
    //         <div className="process-line">
    //           <img src="assets/img/shape/process-line.png" alt />
    //         </div>
    //         <div className="process-card_wrapp">

    //           {
    //             stepwork.map(a => a.stepNumber === '01' && <div className="process-card">
    //               <div className="process-card_icon">
    //                 <span className="number">{a.stepNumber}</span>
    //               </div>
    //               <div className="process-card_content">
    //                 <h2 className="box-title">{a.stepTitle}</h2>
    //                 <p className="process-card_text">{a.stepDetails}</p>
    //               </div>
    //               <div className="process-card_img">
    //                 <img src={a.stapIcon} alt="Process" />
    //               </div>
    //             </div>
    //             )
    //           }


    //           {
    //             stepwork.map(b => b.stepNumber === '02' && <div className="process-card">
    //               <div className="process-card_icon">
    //                 <span className="number">{b.stepNumber}</span>
    //               </div>
    //               <div className="process-card_content">
    //                 <h2 className="box-title">{b.stepTitle}</h2>
    //                 <p className="process-card_text">{b.stepDetails}</p>
    //               </div>
    //               <div className="process-card_img">
    //                 <img src={b.stapIcon} alt="Process" />
    //               </div>
    //             </div>)
    //           }



    //           {
    //             stepwork.map(c => c.stepNumber === '03' && <div className="process-card">
    //               <div className="process-card_icon">
    //                 <span className="number">{c.stepNumber}</span>
    //               </div>
    //               <div className="process-card_content">
    //                 <h2 className="box-title">{c.stepTitle}</h2>
    //                 <p className="process-card_text">{c.stepDetails}</p>
    //               </div>
    //               <div className="process-card_img">
    //                 <img src={c.stapIcon} alt="Process" />
    //               </div>
    //             </div>)
    //           }

    //           {
    //             stepwork.map(d => d.stepNumber === '04' && <div className="process-card">
    //               <div className="process-card_icon">
    //                 <span className="number">{d.stepNumber}</span>
    //               </div>
    //               <div className="process-card_content">
    //                 <h2 className="box-title">{d.stepTitle}</h2>
    //                 <p className="process-card_text">{d.stepDetails}</p>
    //               </div>
    //               <div className="process-card_img">
    //                 <img src={d.stapIcon} alt="Process" />
    //               </div>
    //             </div>)
    //           }




    //         </div>
    //       </div>
    //     </div>
    //   </section>

    // </>

    <>

<section className="feature pos-rel pt-135 pb-130">
        <div className="container">
          <div className="sec-title sec-title--marketing text-center mb-60" data-aos="fade-up" data-aos-duration={600}>
            <span className="subtitle">what we do</span>
            <h2 className="title">We Solve digital Challenges</h2>
          </div>
          <div className="row mt-none-30 row-cols-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-4 row-cols-sm-4 justify-content-center">

            {
              stepwork.map(a=> <div className="col mt-30 p-50">
                <div className="mr-feature" >
                  <div className="xb-item--inner">
                    <div className="xb-item--icon">
                      <img src={a.stapIcon} alt />
                    </div>
                    <h3 className="xb-item--title">{a.stepTitle}</h3>
                    <div className="xb-item--shape">
                      <img src="assets/img/icon/rm_feat_shape.png" alt />
                    </div>
                    <div className="xb-item--bg">
                      <img src="assets/img/shape/rm_feat_bg.png" alt />
                    </div>
                  </div>
                </div>
              </div>)
            }
            
            
            

          </div>
        </div>
        <div className="mr-feature-shape" data-parallax="{&quot;y&quot;: &quot;-90&quot;}">
          <img src="assets/img/shape/mr_shape1.png" alt />
        </div>
      </section>



    
    </>
  );
};

export default WorkSection;