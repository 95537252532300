import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";



const NavBar = () => {

  const [user] = useAuthState(auth);
  const handleSignOut = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);
  const [footersocial, setFootersocial] = useState([]);

    useEffect(() => {
        fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/footer-social`)
            .then((res) => res.json())
            .then((info) => setFootersocial(info));
    }, []);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);


  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setUser(info));
  }, []);




  return (



<>

<header id="xb-header-area" className="site-header header-marketing is-sticky">
 
  <div className="xb-header__wrap xb-header-has-arrow xb-header">
    <div className="container">
      {
        logo.map(l=> <div className="ul_li_between">
          <div className="xb-header__logo">
            <a href="/"><img src={l.logo} alt /></a>
          </div>
          <div className="main-menu__wrap ul_li navbar navbar-expand-lg">
            <nav className="main-menu collapse navbar-collapse">
              <ul>
                
                <li><a href="/"><span>Home</span></a></li>
                <li><a href="#services-sec"><span>Services</span></a></li>
                <li><a href="#about-sec"><span>About</span></a></li>
                <li><a href="#pricing-sec"><span>Pricing</span></a></li>
                <li><a href="#contact-sec"><span>Contact</span></a></li>
  
                {
                          user?.email ?
                            <li>
                              <Link to="/dashboard" className=" text-decoration-none ">Dashboard</Link>
                            </li>
                            :
                            <li>
                              <Link to="/login" className="nav-menu-btn text-decoration-none ">Log in</Link>
                            </li>
                        }
                      
  
                      
                        {
                          users.map(u => u.userEmail === user?.email && u.userRole === 'Admin' &&
                            <li>
                              <Link to="/admin" className="nav-menu-btn text-decoration-none ">Admin</Link>
                            </li>
                          )
                        }
              </ul>
            </nav>
            <div className="xb-header-wrap style-black">
              <div className="xb-header-menu">
                <div className="xb-header-menu-scroll">
                  <div className="xb-menu-close xb-hide-xl xb-close" />
                  <div className="xb-logo-mobile xb-hide-xl">
                    <a href="index.html" rel="home"><img src="assets/img/logo/logo3.svg" alt /></a></div>
                  <div className="xb-header-mobile-search xb-hide-xl">
                    <form role="search" action="#">
                      <input type="text" placeholder="Search..." name="s" className="search-field" />
                    </form>
                  </div>
                  <nav className="xb-header-nav">
                    <ul className="xb-menu-primary clearfix">
                      <li className="menu-item menu-item-has-children">
                        <a href="#"><span>Home</span></a>
                        <ul className="sub-menu">
                          <li className="menu-item active"><a className="active" href="index.html"><span>Financial Consultant</span></a></li>
                          <li className="menu-item"><a href="home-business.html"><span>Business Consultant</span></a></li>
                          <li className="menu-item"><a href="home-digital-marketing.html"><span>Digital Marketing</span></a></li>
                          <li className="menu-item"><a href="home-law.html"><span>Lawyer Firms</span></a></li>
                          <li className="menu-item"><a href="home-insurance.html"><span>Insurance Business</span></a></li>
                          <li className="menu-item"><a href="home-advisor.html"><span>Personal Advisor</span></a></li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-has-children">
                        <a href="#"><span>Pages</span></a>
                        <ul className="sub-menu">
                          <li className="menu-item"><a href="about.html"><span>About</span></a></li>
                          <li className="menu-item"><a href="services.html"><span>Services</span></a></li>
                          <li className="menu-item"><a href="service-single.html"><span>Service Details</span></a></li>
                          <li className="menu-item"><a href="career.html"><span>Career</span></a></li>
                          <li className="menu-item"><a href="career-single.html"><span>Career Details</span></a></li>
                          <li className="menu-item"><a href="team.html"><span>Team</span></a></li>
                          <li className="menu-item"><a href="team-single.html"><span>Team Details</span></a></li>
                          <li className="menu-item"><a href="pricing.html"><span>Pricing</span></a></li>
                          <li className="menu-item"><a href="faq.html"><span>FAQ</span></a></li>
                          <li className="menu-item"><a href="404.html"><span>404</span></a></li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-has-children">
                        <a href="portfolio.html"><span>Portfolio</span></a>
                        <ul className="sub-menu">
                          <li className="menu-item"><a href="portfolio.html"><span>Portfolio</span></a></li>
                          <li className="menu-item"><a href="portfolio-single.html"><span>Portfolio Details</span></a></li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-has-children">
                        <a href="shop.html"><span>Shop</span></a>
                        <ul className="sub-menu">
                          <li className="menu-item"><a href="shop.html"><span>Shop</span></a></li>
                          <li className="menu-item"><a href="shop-single.html"><span>Shop Details</span></a></li>
                          <li className="menu-item"><a href="cart.html"><span>Shop Cart</span></a></li>
                          <li className="menu-item"><a href="checkout.html"><span>Checkout</span></a></li>
                        </ul>
                      </li>
                      <li className="menu-item menu-item-has-children">
                        <a href="blog.html"><span>Blog</span></a>
                        <ul className="sub-menu">
                          <li className="menu-item"><a href="blog.html"><span>Blog</span></a></li>
                          <li className="menu-item"><a href="blog-single.html"><span>Blog Details</span></a></li>
                        </ul>
                      </li>
                      <li className="menu-item"><a href="contact.html"><span>Contact</span></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="xb-header-menu-backdrop" />
            </div>
          </div>
          
        </div> )
      }
     
    </div>
  </div>
</header>




</>
  );
};

export default NavBar;
