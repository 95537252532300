// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbKExksx8ucS02acGhvsjVGlJqk_Yl9ps",
  authDomain: "fitnessandwellnessseo16.firebaseapp.com",
  projectId: "fitnessandwellnessseo16",
  storageBucket: "fitnessandwellnessseo16.appspot.com",
  messagingSenderId: "572533068149",
  appId: "1:572533068149:web:185377574b933f5a56433c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;