import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (

    <>

<div className="bg_img" data-background="assets/img/bg/mr_bg_2.jpg" id="services-sec">
  {/* process start */}
  <section className="process pos-rel pt-140 pb-130">
    <div className="container">

      {
        service.map(t=><div className="sec-title sec-title--marketing text-center mb-90" >
          <span className="subtitle">{t.servicesubHeading}</span>
          <h2 className="title">{t.serviceHeading}</h2>
        </div>)
      }

      
      <div className="mr-process ul_li_between mt-none-50" >
        {
          items.map(s=> <div className="mr-process__item mt-50">
            <div className="xb-item--icon pos-rel">
              <img src={s.serviceIcon} alt />
              <span className="xb-item--icon-bg"><img src="assets/img/shape/mr_prc_bg.png" alt /></span>
            </div>
            <span className="xb-item--check"><img src="assets/img/icon/mr_check.svg" alt /></span>
            <h2 className="xb-item--title">{s.serviceTitle}</h2>
            <p className="xb-item--content">{s.serviceDetails}</p>
            <span className="xb-item--number"></span>
          </div>)
        }
        
        
        
      </div>
    </div>
    <div className="mr-process-shape" data-parallax="{&quot;y&quot;: &quot;-90&quot;}">
      <img src="assets/img/shape/mr_shape1.png" alt />
    </div>
  </section>
  {/* process end */}
  {/* team start */}
  
  {/* team end */}
</div>

     

    </>


  );
};

export default ServicesSection;
