import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const ContactUs = () => {

  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);





  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;


    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,

    };

    const url = `https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        
        alert('Message is Send Thanks');
      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);



  return (




<>



<section className="contact  mt-50 mb-100" id='contact-sec'>
  <div className="container">
    <div className="sec-title text-center mb-45">
      
      <h2 className="title">Contact us Today for Expert <br /> Support Guidance</h2>
    </div>
    <div className="xb-contact__area">
      <form  onSubmit={handleMessage} className="contact-from" action="#">
        <div className="row">
          <div className="col-lg-6">
            <div className="xb-item--field">
              <label htmlFor="name">Name*</label>
              <div className="field-inner pos-rel">
                <span className="icon"><img src="assets/img/icon/ins_user.svg" alt /></span>
                <input id="name" name='name' type="text" placeholder="Your Name" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="xb-item--field">
              <label htmlFor="email">Email*</label>
              <div className="field-inner pos-rel">
                <span className="icon"><img src="assets/img/icon/ins-sms-tracking.svg" alt /></span>
                <input id="email" name='email' type="email" placeholder="info@website.com" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="xb-item--field">
              <label htmlFor="phone">Phone *</label>
              <div className="field-inner pos-rel">
                <span className="icon"><img src="assets/img/icon/call-calling.svg" alt /></span>
                <input id="phone"  name='number' type="text" placeholder="+1 088 01 4800 24" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="xb-item--field">
              <label>Subject *</label>
              <div className="field-inner pos-rel">
                <span className="icon"><img src="assets/img/icon/ins-home-hashtag.svg" alt /></span>
                <input id="subject" name='subject' type="text" placeholder="Your Subject" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="xb-item--field">
              
              <div className="field-inner pos-rel">
                
                <input id="input_phone" type="tel" name="messageStatus"  value="UnRead" hidden placeholder="Your Subject" />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="xb-item--field">
              <label htmlFor="message">Message</label>
              <div className="field-inner pos-rel">
                <span className="icon"><img src="assets/img/icon/messages-2.svg" alt /></span>
                <textarea name="message"  id="message" cols={30} rows={10} placeholder="Write Your Message..." defaultValue={""} />
              </div>
            </div>
          </div>
          <div className="form-button text-center">
            <button className="xb-btn">send a message</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>




</>
  );
};

export default ContactUs;