import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (



    <>

    

<section className="hero-marketing mr-bg pos-rel d-flex align-items-center" data-background="assets/img/bg/mr_hero_bg.jpg">
  <div className="container">
    {
      banners.map(b=><div className="row align-items-center">
        <div className="col-lg-6">
          <div className="mr-hero-content">
            <span className="xb-item--subtitle" ><span><img src="assets/img/icon/hr_icon.png" alt /></span> {b.bannerText}</span>
            <h2 className="xb-item--title"  >{b.bannerHeading} <img id="reload" src="assets/img/icon/decor2.png" alt /></h2>
            <p className="xb-item--content mb-50"  >{b.bannerDetails}</p>
            <div className="xb-item--btn" >
              <a className="xb-btn xb-btn--marketing" href={b.buttonURL}>
                <div className="btn-anim-wrap">
                  <span className="button-text">{b.buttonText}</span>
                  <span className="button-text">{b.buttonText}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mr-hero-img pos-rel">
            <img  src={b.imageOne} alt />
            <div className="mr-hero-img-inner">
              <div className="chart chart--1">
                <img data-aos="zoom-in" data-aos-duration={600} data-aos-delay={500} src="" alt />
              </div>
              <div className="chart chart--2">
                <img data-aos="zoom-in" data-aos-duration={600} data-aos-delay={600} src="" alt />
              </div>
              <div className="chart chart--3">
                <img data-aos="zoom-in" data-aos-duration={600} data-aos-delay={700} src="" alt />
              </div>
              <div className="chart chart--4">
                <img data-aos="zoom-in" data-aos-duration={600} data-aos-delay={800} src="" alt />
              </div>
            </div>
          </div>
        </div>
      </div> )
    }
    
  </div>
  <div className="mr-hero-shape">
    <img src="assets/img/shape/mr_shape.png" alt />
  </div>
</section>







    </>
  );
};

export default Banner;
