import React, { useEffect, useState } from "react";
import { Link, } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;




    const addItem = {
      subemail,



    };

    const url = `https://fitnessandwellnessseoservice16-c12d3bdb0a0a.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Subscribers Request is Sent');
      });


  }










  return (



    <>

    {
      footerSocial.map(a=>  <footer className="xb-footer  footer-marketing mr-noise-bg pt-115 pb-45">
        <div className="container">

          <div className="footer-main mb-60">
            <div className="row mt-none-40">
              <div className="col-lg-4 footer-col mt-40">
                <div className="footer-widget">
                  <h3 className="widget-title">About Us</h3>
                  <p>{a.aboutText}</p>

                  

                </div>
              </div>
              <div className="col-lg-4 footer-col mt-40 ">
                <div className="footer-widget">
                  <h3 className="/">Quick Links</h3>
                  <ul className="footer-links list-unstyled">
                    <li><a href="/">Home</a></li>
                    <li><a href="#about-sec">About</a></li>
                    <li><a href="#services-sec">Services</a></li>
                    <li><a href="#pricing-sec">Pricing Plan</a></li>
                    <li><a href="#contact-sec">Contact us</a></li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 footer-col mt-40">
                {
                  footerAddress.map(f=> <div className="mr-footer-info mb-30">
                 
                    <h3 className="xb-item--title">Get in touch</h3>
                    <p className="xb-item--info">{f.phoneNumber} <br />
                    {f.emailAddress} <br />
                    {f.location}</p>
                    <ul className="xb-item--social ul_li">
                      <li><a href={a.fblink}><i className="fab fa-facebook-f" /></a></li>
                      <li><a href={a.youlink}><i className="fab fa-youtube" /></a></li>
                      <li><a href={a.inslink}><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div> )
                }
                


              </div>

            </div>
          </div>

          {
            footerCopyright.map(c=> <div className="mr-footer-copyright  text-center">
              <p className="mt-15 ">{c.copyrightText}</p>
  
            </div>)
          }

          
        </div>
      </footer>)
    }

      





    </>
  );
};

export default Footer;
